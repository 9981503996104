<template>
  <div class="candidate">
    <div class="candidate__index">
      {{ candidateData.index + 1 + (currentPage - 1) * 25 }}
    </div>
    <div class="candidate__info">
      <div class="candidate__info__wrap">
        <div class="flex flex-row">
          <a
            :href="`https://www.linkedin.com/in/${originalData.profile_id}`"
            target="_blank"
            rel="noopener noreferrer"
            class="candidate-link"
          >
            <Icon name="svg-linkedin" class="size-5 me-2 mt-1" />
          </a>
          <span
            class="flex text-secondary-900 text-2xl font-semibold cursor-pointer"
            @click="$emit('view-candidate')"
          >
            {{ originalData.name }}
          </span>
        </div>
        <div class="candidate-position">
          <div class="candidate-position__wrap">
            {{ originalData.title }}
          </div>
          <div class="candidate-location" v-if="location">
            <Icon name="svg-location" class="size-4 me-1" />
            {{ location }}
          </div>
        </div>
      </div>

      <div class="company-section">
        <div class="company-section__wrap flex flex-row gap-3">
          <div
            v-if="originalData.company_name"
            class="company-section__info flex items-center gap-3"
          >
            <a
              :href="originalData.company_linkedin_url"
              target="_blank"
              rel="noopener noreferrer"
              class="company-link"
            >
              <img
                v-if="originalData.company_logo"
                :src="originalData.company_logo"
                class="company-img"
                :alt="originalData.company_name"
              />
              <div v-else>
                <Icon name="svg-building" class="company-img"></Icon>
              </div>
              <span class="text-secondary-600 text-sm">
                {{ originalData.company_name }}
              </span>
            </a>
          </div>
          <div v-else class="company-section__info flex items-center gap-3">
            <Icon name="svg-building" class="company-img"></Icon>
            <span class="text-secondary-600 text-sm">N/A</span>
          </div>
          <Icon name="svg-dot" class="size-1" v-if="originalData.industry"/>
          <div v-if="originalData.industry" class="company-section__industry flex items-center">
            <Icon name="svg-portfolio" class="size-4 me-1" />
            {{ originalData.industry }}
          </div>
        </div>
      </div>

      <div class="exp-items">
        <div v-if="originalData.total_experience_in_position" class="exp-item">
          <Icon name="input-time" class="exp-item__icon" />
          <span class="exp-item__title">
            Role: {{ convertMonthsToYearsMonths(originalData.total_experience_in_position) }}
          </span>
        </div>
        <div v-if="originalData.total_time_in_company" class="exp-item">
          <Icon name="input-time" class="exp-item__icon" />
          <span class="exp-item__title">
            Company: {{ convertMonthsToYearsMonths(originalData.total_time_in_company) }}
          </span>
        </div>
        <div v-if="originalData.total_career_experience" class="exp-item">
          <Icon name="input-time" class="exp-item__icon" />
          <span class="exp-item__title">
            Career: {{ convertMonthsToYearsMonths(originalData.total_career_experience) }}
          </span>
        </div>
      </div>
    </div>
    <div class="candidate__actions">
      <slot name="buttons" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import { convertMonthsToYearsMonths } from '@/utils'

interface IProps {
  candidateData: Object
  currentPage: number
}

const props = defineProps<IProps>()

const originalData = computed(() => props.candidateData.original)

const location = computed(() =>
  [originalData.value?.city, originalData.value?.state, originalData.value?.country]
    .filter((x) => x)
    .join(', ')
)
</script>

<style>
.candidate {
  @apply flex flex-col md:flex-row md:py-4 border border-secondary-100 max-md:rounded-2xl md:border-0;

  .candidate__index {
    @apply content-center text-sm text-secondary-900 font-semibold text-center py-1 px-4 md:p-2 bg-grey-75 rounded-t-2xl md:rounded-r-lg md:rounded-tl-none;
  }

  .candidate__info {
    @apply flex flex-col gap-3 md:gap-2 px-3 md:px-0 md:ms-2 mt-4 md:mt-0;

    .candidate__info__wrap {
      @apply md:mb-1;

      .candidate-link {
        @apply flex;
      }
      .candidate-position {
        @apply flex flex-wrap gap-2 ms-8 mt-2 min-h-7 items-center;

        .candidate-position__wrap {
          @apply text-base leading-5 font-medium;
        }

        .candidate-location {
          @apply flex px-2 py-1 text-burgundy-800 text-sm bg-burgundy-50 rounded-lg;
        }
      }
    }

    .company-section {
      .company-section__wrap {
        @apply flex flex-wrap items-center gap-2;

        .company-section__info {
          @apply flex gap-2;
          .company-link {
            @apply flex items-center font-medium;
          }
          .company-img {
            @apply size-8 rounded-full object-cover me-2;
          }
        }

        .company-section__industry {
          @apply px-2 py-1 text-secondary-600 text-sm font-normal rounded-lg bg-grey-75;
        }
      }
    }

    .exp-items {
      @apply flex flex-row flex-wrap gap-1 font-normal;

      .exp-item {
        @apply flex items-center px-2 py-1 text-secondary-700;

        .exp-item__icon {
          @apply size-4 me-1;
        }

        .exp-item__title {
          @apply text-sm;
        }
      }
    }
  }

  .candidate__actions {
    @apply flex flex-row md:flex-col md:gap-4 px-3 py-4 md:p-0 justify-end items-start md:items-end flex-grow h-full;
  }
}
</style>
