<template>
  <div class="flex flex-row gap-3">
    <div class="flex flex-col mt-8">
      <a :href="workExperience.current.linkedin_url" target="_blank">
        <img
          v-if="workExperience.current.logo"
          :src="workExperience.current.logo"
          :alt="workExperience.current.company_name"
          class="size-10 rounded-full object-cover me-2"
        />
        <div v-else>
          <Icon name="svg-building" class="size-10 rounded-full object-cover me-2"></Icon>
        </div>
      </a>
    </div>
    <div class="flex flex-col gap-3 w-full">
      <div class="flex max-sm:flex-col flex-row gap-2.5 md:items-center py-1.5">
        <span class="text-xl leading-6 text-grey-900 font-semibold">{{
          workExperience.current.title
        }}</span>
        <div
          v-if="
            workExperience.current.work_country ||
            workExperience.current.work_state ||
            workExperience.current.work_city
          "
          class="flex bg-burgundy-50 rounded-lg text-burgundy-800 p-1 px-2 items-center gap-1 lg:max-h-[27px]"
        >
          <Icon name="svg-location" class="size-4 stroke-burgundy-800 text-burgundy-800" />
          <span class="text-sm flex-wrap leading-5">
            {{ workExperience.current.work_city ? workExperience.current.work_city + ',' : '' }}
            {{ workExperience.current.work_state ? workExperience.current.work_state + ',' : '' }}
            {{ workExperience.current.work_country ? workExperience.current.work_country : '' }}
          </span>
        </div>
      </div>
      <div
        class="flex flex-row gap-5 max-md:gap-2 items-center justify-start max-h-[19px]"
        v-if="workExperience.current.total_experience_in_position"
      >
        <div class="flex items-center max-md:gap-1 gap-1.5">
          <Icon name="input-time" class="size-4 text-secondary-700" />
          <span class="text-secondary-700 text-sm leading-[19px]"
            >Role:
            {{
              convertMonthsToYearsMonths(workExperience.current.total_experience_in_position)
            }}</span
          >
        </div>
        <div class="flex items-center max-md:gap-1 gap-1.5" v-if="workExperience.current.starts_at">
          <Icon name="svg-calendar" class="size-4 text-secondary-700" />
          <span class="text-secondary-700 text-sm leading-[19px]"
            >{{ formatDate(workExperience.current.starts_at) }} -
            {{ formatDate(workExperience.current.ends_at) ?? 'Current' }}</span
          >
        </div>
      </div>

      <div class="flex flex-row flex-wrap gap-1.5 items-center">
        <span class="text-base leading-6 text-grey-900">{{
          workExperience.current.company_name ?? workExperience.current.company
        }}</span>
        <div
          v-if="workExperience.current.industry"
          class="flex bg-grey-75 rounded-lg text-secondary-600 p-1 items-center text-sm lg:max-h-[27px]"
        >
          <Icon name="svg-portfolio" class="size-4 text-secondary-600 mr-1" />
          {{ workExperience.current.industry ?? 'N/A' }}
        </div>
      </div>

      <div class="flex flex-row flex-wrap gap-1.5 items-center">
        <Button
          variant="outline-primary"
          class="flex border-none gap-1 items-center hover:underline hover:bg-transparent !p-0 m-0 leading-[22px] h-[22px]"
          @onClick="toggleCompanyInfo"
        >
          <Icon :name="showCompanyInfo ? 'svg-minus' : 'svg-add'" class="size-4 hover:underline" />
          {{ companyInfoMessage }}
        </Button>
      </div>
      <div v-if="showCompanyInfo" class="flex flex-col">
        <div class="flex flex-col gap-4 p-4 items-center rounded-lg bg-grey-75">
          <div class="flex flex-col md:flex-row gap-4 md:gap-16 w-full">
            <div
              class="flex flex-col gap-2"
              v-if="
                workExperience.current.company_country ||
                workExperience.current.company_state ||
                workExperience.current.company_city
              "
            >
              <div class="flex flex-row">
                <span class="text-secondary-600 text-sm leading-5">Location</span>
              </div>
              <div class="flex flex-row">
                <span
                  class="text-sm flex items-center gap-1.5 flex-wrap text-grey-900 leading-[19px]"
                >
                  {{ workExperience.current.company_city }}
                  <Icon name="svg-dot" class="flex size-1" />
                  {{ workExperience.current.company_state }}
                  <Icon name="svg-dot" class="flex size-1" />
                  {{ workExperience.current.company_country }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex flex-row">
                <span class="text-secondary-600 text-sm leading-5">Industry</span>
              </div>
              <div class="flex flex-row">
                <span class="text-sm text-grey-900 leading-[19px]">
                  {{ workExperience.current.industry ?? 'N/A' }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex flex-row">
                <span class="text-secondary-600 text-sm leading-5">Company Headcount</span>
              </div>
              <div class="flex flex-row">
                <span class="text-sm text-grey-900 leading-[19px]">
                  {{
                    workExperience.current.company_size
                      ? companySizeFormatted(workExperience.current.company_size)
                      : 'N/A'
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-16 w-full">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row">
                <span class="text-secondary-600 text-sm">Description</span>
              </div>
              <div class="flex flex-row">
                <span class="text-sm text-grey-900 leading-[19px]">
                  {{ workExperience.current.company_description ?? 'N/A' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="displayHistory && workExperience.positions.length > 1" class="flex flex-row">
        <div class="flex flex-col">
          <CompanyHistory :company-exp-history="workExperience.positions" />
        </div>
      </div>

      <div class="flex flex-row" v-if="workExperience.current.description">
        <span class="text-sm text-grey-900 leading-[19px]">
          {{ workExperience.current.description }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import CompanyHistory from '@/components/Candidate/Experience/CompanyHistory.vue'
import { computed, defineProps, ref, withDefaults } from 'vue'
import type { ExperienceItem } from '@/components/Candidate/Experience/experience'
import { convertMonthsToYearsMonths } from '@/utils'

interface IProps {
  workExperience: ExperienceItem
  displayHistory?: boolean
}

withDefaults(defineProps<IProps>(), {
  displayHistory: true
})
const showCompanyInfo = ref(false)
const companySizeRange = [1, 2, 10, 50, 200, 500, 1000, 5000, 10000]

const companyInfoMessage = computed(() =>
  showCompanyInfo.value ? 'Hide Company Info' : 'Show Company Info'
)

const toggleCompanyInfo = () => {
  showCompanyInfo.value = !showCompanyInfo.value
}

const formatDate = (date) => {
  if (!date) {
    return 'Current'
  }

  const fullDate = new Date(date)

  return `${fullDate.toLocaleString('en-US', { month: 'short' })}. ${fullDate.getFullYear()}`
}

const companySizeFormatted = (companySize: number) => {
  const rangeIndex = companySizeRange.indexOf(Number(companySize))
  console.log(typeof companySize)

  if (companySizeRange[rangeIndex + 1] == undefined) return `${companySize.toLocaleString()}+`

  return `${companySizeRange[rangeIndex].toLocaleString()} - ${companySizeRange[rangeIndex + 1].toLocaleString()}`
}
</script>
