<template>
  <TipCard
    :class="{ 'max-lg:hidden': !showTips }"
    class="mt-2 md:mt-4 main-tip"
    title="Job Title Search"
    description="Create an ideal personalized Archetype for maximum search flexibility. Select current role and experience, industries or even specific companies. Add past experience roles for laser-focused results."
  />
  <div class="lg:border lg:rounded-2xl lg:p-6 mt-10">
    <form @submit.prevent="search" id="title-search-form">
      <div class="flex flex-col gap-8">
        <CurrentPosition @jobTitleChange="jobTitleChange" />
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Location Information"
            description="Enter the desired location of the candidate. You can select several locations at once."
          />
          <CandidateLocation
            @locationChange="locationChange"
            @locationRadiusChange="locationRadiusChange"
            :show-error-message="locationError"
          />
        </div>
        <div class="flex flex-col md:flex-row gap-4 justify-end">
          <Button
            class="bg-primary-100 !text-primary-700 hover:!text-white md:w-40 w-full text-base"
            type="submit"
            variant="primary"
          >
            Quick Search
          </Button>

          <Button type="button" variant="outline-primary" @onClick="onAdvanceSearch">
            Build Advanced Search
          </Button>
        </div>
      </div>
    </form>
  </div>

  <fieldset
    id="advanced-search"
    class="mt-10"
    form="title-search-form"
    v-show="searchStore.isAdvancedSearch"
  >
    <legend class="text-grey-900 font-bold text-3xl mb-2">Advanced Search</legend>
    <div class="lg:relative mt-8 flex flex-col gap-8">
      <div>
        <TipCard
          :class="{ 'max-lg:hidden': !showTips }"
          class="mb-4 advanced-left-side lg:-ms-6"
          title="Experience Information"
          description="Please indicate the candidate's preferred experience in current role and total experience. You can also add the candidate's previous experience."
        />
        <WorkExperience
          :role-min-years="route.query.prefill ? searchStore.searchPayload?.total_role_exp : null"
          :career-min-years="route.query.prefill ? searchStore.searchPayload?.career_exp : null"
          :past-roles="route.query.prefill ? searchStore.searchPayload?.past_experience : []"
          @roleMinYearsChange="(value: string) => (roleMinYears = value)"
          @careerMinYearsChange="(value: string) => (careerMinYears = value)"
          @pastRolesChange="(value: PastExperience[]) => (pastRoles = value)"
        />
      </div>
      <div>
        <TipCard
          :class="{ 'max-lg:hidden': !showTips }"
          class="mb-4 advanced-left-side lg:-ms-6"
          title="Keyword Information"
          description="For each keyword defined, you can choose between Required or Desired. If Required, the candidate(s) must have this exact keyword contained within their career profile About blurb, career headline, current/past experience items, and education. If Desired, Lookalikes will try and find candidates with the keyword, but may still return results without all Desired keywords."
        >
          <template #description>
            <div class="flex flex-col gap-2">
              <p>
                <span class="font-bold">Required Keywords</span>
                <br />
                Every Required keyword must appear in a candidate's profile for inclusion (it
                functions like an AND statement between keywords). The system checks their summary,
                headline and experience. Missing one excludes them.
              </p>
              <p>
                <span class="font-bold">Desired Keywords</span>
                <br />
                More flexible—candidates with these terms are prioritized but not excluded if some
                are missing.
              </p>
              <p>
                <span class="font-bold">How They Work Together</span>
                <br />
                This allows you to filter must-have criteria while considering preferred
                qualifications.
              </p>
            </div>
          </template>
        </TipCard>
        <Keywords
          title="Keywords"
          @change="handleKeywordsChange"
          :keywords="
            route.query.prefill && searchStore.searchPayload?.keywords
              ? searchStore.searchPayload?.keywords
              : null
          "
        />
      </div>
      <div>
        <TipCard
          :class="{ 'max-lg:hidden': !showTips }"
          class="mb-4 advanced-left-side lg:-ms-6"
          title="Education Information"
        >
          <template #description>
            <p>
              Enter or paste education keywords, such as:
              <br />
              - Name of Institution, University or College
              <br />
              - Type of Degree (Bachelors, Masters, PHD, etc)
              <br />
              - Field of Study (Marketing, Computer Science, Finance)
            </p>
          </template>
        </TipCard>
        <Keywords
          title="Education Keywords"
          @change="handleEducationKeywordsChange"
          :keywords="
            route.query.prefill && searchStore.searchPayload?.education_keywords
              ? searchStore.searchPayload?.education_keywords
              : null
          "
        />
      </div>
      <div class="lg:relative">
        <TipCard
          :class="{ 'max-lg:hidden': !showTips }"
          class="mb-4 advanced-left-side lg:-ms-6"
          title="Employer Information"
          description="Enter the number of employees, company name, and industry. You can select several employers and industries at once."
        />
        <CandidateEmployer
          :selected-headcount="
            route.query.prefill ? searchStore.searchPayload?.company_size_include : null
          "
          :selected-employers="
            route.query.prefill ? searchStore.searchPayload?.companies_include : null
          "
          :selected-industries="
            route.query.prefill ? searchStore.searchPayload?.industries_include : null
          "
          :selected-past-employers="
            route.query.prefill ? searchStore.searchPayload?.past_employers_include : null
          "
          @headcountChange="headCountChange"
          @employerChange="employerChange"
          @industryChange="industryChange"
          @pastEmployerChange="pastEmployerChange"
        />
      </div>
      <Button
        class="w-full md:w-60 mt-8 self-end"
        form-id="title-search-form"
        type="submit"
        variant="primary"
      >
        Advanced Search
      </Button>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'

import TipCard from '@/components/TipCard.vue'
import Button from '@/components/Buttons/Button.vue'
import CurrentPosition from '@/views/Search/Components/CurrentPosition.vue'
import WorkExperience from '@/views/Search/Components/WorkExperience.vue'
import CandidateEmployer from '@/views/Search/Components/CandidateEmployer.vue'
import CandidateLocation from '@/views/Search/Components/CandidateLocation.vue'
import Keywords from '@/views/Search/Components/Keywords.vue'

import { useSearchStore } from '@/stores/search'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'
import type { PastRole } from '@/stores/types'
import { setRecentLocations } from '@/utils/recent-searches'

interface IProps {
  showTips: boolean
}

defineProps<IProps>()
const emit = defineEmits(['loading', 'error'])

const router = useRouter()
const route = useRoute()
const breadcrumbsStore = useBreadcrumbsStore()
const searchStore = useSearchStore()

const currentPosition = ref<string[]>([])
const currentCanonicalTitle = ref<string[]>([])
const roleMinYears = ref(0)
const careerMinYears = ref(0)
const pastRoles = ref<PastRole[]>([])
const headCount = ref<number[]>([])
const companies = ref<string[]>([])
const industries = ref<string[]>([])
const pastEmployers = ref<string[]>([])
const locations = ref<[]>([])
const locationRadius = ref(25)
const noResults = ref(false)
const keywords = ref([])
const educationKeywords = ref([])
const titleInclude = ref([])
const locationError = ref(false)

const handleKeywordsChange = (keywordsInput: { desired: boolean; keywords: string[] }[]) => {
  keywords.value = keywordsInput
}

const handleEducationKeywordsChange = (
  keywordsInput: { desired: boolean; keywords: string[] }[]
) => {
  educationKeywords.value = keywordsInput
}

const jobTitleChange = (positions: { label: string; value: string }[]) => {
  currentPosition.value = positions.map((position) => position.label)
  currentCanonicalTitle.value = positions.map((position) => position.value)
  titleInclude.value = positions.map((position) => ({
    label: position.label,
    value: position.value,
    type: 'include'
  }))
}

const locationChange = (selectedLocations: { value: string; label: {} }[]) => {
  locations.value = selectedLocations
}

const locationRadiusChange = (value: number) => {
  locationRadius.value = value
}

const headCountChange = (headCounts: { label: string; value: number }[]) => {
  headCount.value = headCounts.flatMap((headCount) => {
    if (headCount.value === 1) {
      return [1, 2] // this is how daniel wants to indicate the first range, for the search query
    }
    return [headCount.value]
  })
}

const employerChange = (employerOptions: { label: string; value: string }[]) => {
  companies.value = employerOptions.map((employer) => employer.value)
}

const pastEmployerChange = (pastEmployerOptions: { label: string; value: string }[]) => {
  pastEmployers.value = pastEmployerOptions.map((pastEmployer) => pastEmployer.value)
}

const industryChange = (industryOptions: string[]) => {
  industries.value = industryOptions
}

const filterEmptyPastRoles = (pastRoles: PastRole[]) => {
  return pastRoles.filter((value) => value.label !== '')
}

const onAdvanceSearch = () => {
  searchStore.setAdvanceSearch(true)
  nextTick(() => {
    scrollTo('advanced-search')
  })
}

const scrollTo = (section) => {
  const isDesktop = window.innerWidth >= 1024 // Example breakpoint for desktop
  const element = document.getElementById(section)

  if (isDesktop) {
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - 100 // 100px offset from top

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  } else {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const search = async () => {
  if (locations.value.filter((location) => location.type === 'include').length === 0) {
    locationError.value = true
    return
  }

  emit('loading', true)
  noResults.value = false

  locationError.value = false

  const payload = {
    locations: locations.value,
    location_radius: locationRadius.value,
    title: currentPosition.value,
    title_include: titleInclude.value,
    canonical_title: currentCanonicalTitle.value,
    total_role_exp: roleMinYears.value,
    career_exp: careerMinYears.value,
    industries_include: industries.value,
    companies_include: companies.value,
    past_employers_include: pastEmployers.value,
    company_size_include: headCount.value,
    past_experience: pastRoles.value.length > 0 ? filterEmptyPastRoles(pastRoles.value) : [],
    keywords: keywords.value,
    education_keywords: educationKeywords.value
  }

  try {
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/search/title`, payload)

    searchStore.setSearchState(res.data, payload)
    setRecentLocations(locations.value)

    breadcrumbsStore.setBreadcrumbs([
      { title: 'Job Title Search', route: { name: 'homePage', query: { tab: 'job-title' } } }
    ])

    router.push({ name: 'searchResults', params: { searchId: res.data.search_id } })
  } catch (err) {
    console.log(err)
    emit('error')
  } finally {
    emit('loading', false)
  }
}
</script>

<style scoped>
.main-tip {
  @apply lg:mt-0 lg:absolute lg:top-0 lg:left-0 lg:max-w-xs;
}
.left-side {
  @apply lg:absolute lg:-left-87 lg:max-w-xs;
}

.advanced-left-side {
  @apply lg:absolute lg:-left-80 lg:max-w-xs;
}
</style>
